
import { TheMask } from 'vue-the-mask'
import { Component, Prop } from 'nuxt-property-decorator'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import DocUin from '@pp-frontend/shared-packages/classes/doc-value/DocUin'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import InfoBtn from './InfoBtn.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchEmailField from '~/components/search-form/SearchEmailField.vue'
import SearchFormOfertaText from '~/components/search-form/SearchFormOfertaText.vue'

@Component({
  components: {
    SearchFormOfertaText,
    SearchEmailField,
    InfoBtn,
    TheMask
  }
})
export default class extends SearchFormAbstract {
  @Prop() dataErrorFormValidations!: ErrorValidation

  USER_EMAIL_REQUIRED = true
  formData = new DocUin()
  fieldId = FieldsId.UIN
  isClientErrorValid: string = ''

  errorClientValid () {
    this.isClientErrorValid = ''
    this.$v.$touch()

    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.document_value.$error) {
        this.isClientErrorValid = ' <span class="error-msg__item">00000000000000000000</span>'
      }
    }
  }

  getType (): string {
    return 'gibdd'
  }
}
