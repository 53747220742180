
import { TheMask } from 'vue-the-mask'
import { Component, Prop, Ref } from 'nuxt-property-decorator'
import SearchFormAbstract from '@pp-frontend/shared-packages/classes/search-form/SearchFormAbstract'
import { FieldsId } from '@pp-frontend/shared-packages/types/enum/FieldsId'
import DocCtc from '@pp-frontend/shared-packages/classes/doc-value/DocCtc'
import LicencePlateInput from '../LicencePlateInput.vue'
import InfoBtn from './InfoBtn.vue'
import { ErrorValidation } from '~/types/ErrorValidation'
import SearchEmailField from '~/components/search-form/SearchEmailField.vue'
import SearchFormOfertaText from '~/components/search-form/SearchFormOfertaText.vue'

@Component({
  components: {
    SearchFormOfertaText,
    SearchEmailField,
    InfoBtn,
    TheMask,
    LicencePlateInput
  }
})
export default class extends SearchFormAbstract {
  @Prop({ default: true }) showLicencePlate!: boolean
  @Ref() ctcField!: HTMLInputElement
  @Prop({
    type: Object,
    default: () => ({
      isError: false,
      msgError: ''
    })
  }) dataErrorFormValidations!: ErrorValidation

  USER_EMAIL_REQUIRED = true
  formData = new DocCtc()
  fieldId = FieldsId.CTC
  isClientErrorValid: string = ''

  maskTokens = {
    '#': { pattern: /\d/ },
    F: {
      pattern: /[0-9АВЕКМНОРСТУХавекмнорстухABEKMHOPCTYXabekmhopctyx]/,
      transform (v: string) {
        const engLetters = 'ABEKMHOPCTYXabekmhopctyx'
        // eslint-disable
        const translations = {
          A: 'А',
          B: 'В',
          E: 'Е',
          K: 'К',
          M: 'М',
          H: 'Н',
          O: 'О',
          P: 'Р',
          C: 'С',
          T: 'Т',
          Y: 'У',
          X: 'Х'
        }
        if (engLetters.includes(v)) {
          return translations[v.toLocaleUpperCase()]
        }
        return v.toLocaleUpperCase()
      }
    }
  }

  onInputLicence (e: any) {
    (this.$v.formData as any).additional_fields.licence_plate.$reset()
    if (e.length === 9 && this.$route.name !== 'ctc-not-found') {
      this.$nextTick(() => (this.ctcField as any).$el.focus())
    }
  }

  beforeMount () {
    this.formData.additional_fields.licence_plate = this.$route.query.licence_plate as string | undefined
  }

  errorClientValid () {
    this.isClientErrorValid = ''
    this.$v.$touch()

    if (!this.dataErrorFormValidations.isError) {
      // @ts-ignore
      if (this.$v.formData.additional_fields.$error) {
        this.isClientErrorValid = ' гос. номеров: <span class="error-msg__item">A 000 AA 000</span> <span class="error-msg__item">A 000 AA 00</span>'
        // @ts-ignore
      } else if (this.$v.formData.document_value.$error) {
        this.isClientErrorValid = ' свидетельства о регистрации ТС: <span class="error-msg__item">00 АА 000000</span> <span class="error-msg__item">00 00 000000</span>'
      }
    }
  }

  getType (): string {
    return 'gibdd'
  }
}
